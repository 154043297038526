@tailwind base;
@tailwind components;
@tailwind utilities;

#draggableContainer {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  position: absolute;
  top: -300px;
}

.flow-wrapper {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}

.flow-wrapper header {
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-bottom: 1px solid rgba(229, 231, 235, 1);
}

.flow-editor {
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  display: flex;
  flex-grow: 1;
}

.flow-editor .flow-editor-wrapper {
  flex-grow: 1;
}

.flow-editor aside {
  border-right: 1px solid #eee;
  font-size: 12px;
  background: #fcfcfc;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.flow-editor aside.sidebar {
  height: calc(100vh - 64px);
  overflow-y: scroll;
}

.flow-editor aside .wrapper {
  padding: 10px;
}

.flow-editor aside.inspector {
  border-left: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  width: 300px;
  border-radius: 5px;
}

.dndnode {
  background: #FFF;
  height: 60px;
  width: 100%;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  color: #666;
  border-color: #ccc;
  border-radius: 5px;
} 

.disabled {
  background-color: #e0e0e0 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.react-flow__panel {}

.react-flow__edge {
  stroke-width: 2px;
}

.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: #FFF;
}

.react-flow__node {
  background: none;
  font-size: 10px;
  border-color: none;
  border-width: 0px;
  width: auto;
  height: auto;
  padding: 0px;
}

.react-flow__node .bp-window {
  background: #fff;
  font-size: 10px;
  border-color: #fff;
  border-width: 1px;
  width: 200px;
  height: auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.react-flow__node.selected .bp-window {
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.1);
}

.react-flow__node .bp-window {
  position: relative;
}

.react-flow__node .bp-icon {
  position: absolute;
  /* transform: translate(50%); */
  height: 32px;
  width: 32px;
  right: -5px;
  top: -10px;
  text-align: center;
  display: flex;
  color: #333;
  background: #FFF;
  /* padding: 10px; */
  border-radius: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.react-flow__node .bp-icon span {
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 100%;
  line-height: 28px;
  overflow: hidden;
}

.react-flow__node .bp-window .bp-label,
.react-flow__node .bp-window .bp-body {
  padding: 5px;
}

.react-flow__node .bp-window .bp-body {
  padding: 10px;
}

.react-flow__node .bp-window .bp-label {
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
  font-size: 8px;
}

.react-flow__node .bp-window .bp-body .inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 1px;
}

.react-flow__node .bp-window .bp-body .inputWrapper label {
  margin-right: 10px;
}

.react-flow__node .bp-window .bp-body .inputWrapper input[type=text] {
  width: 100%;
  padding: 2px;
  margin: 0px;
  border: none;
  font-size: 12px;
  line-height: 12px;
  border-radius: 2px;
  border: 1px solid #ccc;
  color: #666;
  background-color: #fff;
  text-align: center;

}



.react-flow__node .react-flow__handle--in,
.react-flow__node .react-flow__handle--out {
  top: 40px;
}


.react-flow__node .handletext {
  position: absolute;
  top: -4px;
  right: 11px;
  font-size: 8px;
  text-align: right;
  color: #666;
  width: 34px;
}

.react-flow__controls button {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid #333;
  border-bottom: none;
}

.react-flow__controls button:hover {
  background-color: rgb(37, 37, 37);
}

.react-flow__controls button:first-child {
  border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
  border-bottom: 1px solid #333;
  border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
  fill: #999;
}

.react-flow__attribution {
  display: none;
}

.react-flow__handle {
  width: 10px;
  height: 10px;
  background-color: #0B2447;
  border-width: 2px;
}

.react-flow__handle.executable {
  border-radius: 100%;
  width: 12px;
  height: 12px;
}

.react-flow__handle-top {
  top: -4px;
}

.react-flow__handle-bottom {
  bottom: -4px;
}

.react-flow__handle-left {
  left: -4px;
}

.react-flow__handle-right {
  right: -4px;
}

.react-flow__handle.executable.react-flow__handle-right {
  right: -7px;
}

.react-flow__handle.executable.react-flow__handle-left {
  left: -7px;
}

.react-flow__handle.executable.react-flow__handle-right .handletext {
  position: absolute;
  top: 0px;
  right: 14px;
  font-size: 8px;
  text-align: right;
  color: #666;
  width: 34px;
}

.react-flow__handle.executable.react-flow__handle-left .handletext {
  top: 0px;
  left: 14px;
  position: absolute;
  top: 0px;
  right: 14px;
  font-size: 8px;
  text-align: left;
  color: #666;
  width: 34px;
}

.selectbox {
  width: 240px;
  max-width: 240px;
  min-width: 240px;
}

.status-tag {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 15px;
  color: white;
}

.status-tag.error {
  background-color: rgba(242, 38, 19, 0.9);
}

.status-tag.success {
  background-color: #4CAF50;
}

.status-tag.warning {
  background-color: #FFA500;
}

.status-tag.info {
  background-color: #bdc3c7;
}

.status-tag.debug {
  background-color: #95a5a6;
}

.status-tag.stopped {
  background-color: #e67e22;
}

.input-style {
  border: 1px solid #ccc !important;
  margin: 0px;
  display: block;
  color: rgb(71, 85, 105) !important;
  font-weight: 500;
  background: rgb(250, 250, 250);
  border-radius: 5px !important;
  height: 30px;
  width: 100%;
  padding: '5px';
  font-size: 12px !important;
}

.textarea-style {
  border: 1px solid #ccc !important;
  margin: 0px;
  display: block;
  color: rgb(71, 85, 105) !important;
  font-weight: 500;
  background: rgb(250, 250, 250);
  border-radius: 5px !important;
  width: 100%;
  padding: '5px';
  font-size: 12px !important;
}

.icon {
  font-size: 12px;
}

.text-label{
  color: #2c3e50;
  font-weight: 600;
}

.text-value{
  color: #34495e;
  font-weight: 500;
}

.text-title{
  color: #2c3e50;
  font-weight: 400;
}

.node-header{
  background-color: #f5f5f5;
    padding: 5px;
    border-radius: 5px; 
    margin-bottom: 10px; 
    text-align: center;
}

.handle-source-right {
  background: #555 !important;
  position: relative !important;
  right: -20px !important;
  top: -7px !important;
}

.handle-source-left {
  background: #555 !important;
  position: relative !important;
  left: -20px !important;
  top: -7px !important;
}

.gradient-bg {
  background-image: linear-gradient(to right, green, black);
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  color: #fff;
}

.gradient-bg-blueprint {
  background-image: linear-gradient(to right, blue, black);
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  color: #fff;
}

.gradient-bg-output {
  background-image: linear-gradient(to right, #683176, black);
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  color: #fff;
}

.bg-input-output{
  background-color: #ecf0f1;
  border-radius: 5px !important;
  margin-bottom: 10px;
  padding: 5px;
}

.bg-input-node{
  background-color: #ecf0f1;
  border-radius: 5px !important;
  margin-bottom: 10px;
  padding: 5px;
}

.bg-output-node{
  background-color: #ecf0f1;
  border-radius: 5px !important;
  padding: 5px;
}

.margin-fragment{
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.icon-edit {
  margin-left: 5px;
  color: #666;
  cursor: pointer;
  font-size: 8px;
}
